import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import path from 'apex-web/lib/helpers/path';
import { getBEMClasses } from 'apex-web/lib/helpers/cssClassesHelper';
import ProductIconContainer from 'apex-web/lib/components/common/ProductIcon/ProductIconContainer';
import CryptoNavItemsContainer from '../WalletNavItems/CryptoNavItemsContainer';
import APIcon from 'apex-web/lib/components/common/APIcon';
import FiatNavItemsContainer from '../WalletNavItems/FiatNavItemsContainer';

import './WalletRow.css';

var walletRowClasses = getBEMClasses('wallet-row');

var WalletRow = function WalletRow(props, context) {
  var iconFileName = props.iconFileName,
    ProductSymbol = props.ProductSymbol,
    ProductFullName = props.ProductFullName,
    isCrypto = props.isCrypto,
    ProductId = props.ProductId,
    width = props.width,
    detailsLink = props.detailsLink;

  var replaceActivityLink = width < 768 ? true : false;
  var iconSize = width > 1024 ? 44 : 28;

  // hide deposit/withdraw button for USD
  var hideActivityBtn = ProductId === 6;

  var renderActivityLink = function renderActivityLink() {
    return React.createElement(
      'div',
      { className: walletRowClasses('activity-link') },
      React.createElement(
        Link,
        {
          className: walletRowClasses('activity-link'),
          to: path(detailsLink),
          onClick: props.selectPositionAndSave
        },
        context.t('Details'),
        ' ',
        React.createElement(APIcon, {
          name: 'arrowNext',
          customClass: walletRowClasses('next-icon')
        })
      )
    );
  };

  return React.createElement(
    'div',
    { className: walletRowClasses() },
    React.createElement(
      'div',
      { className: walletRowClasses('icon-label') },
      React.createElement(
        'div',
        { className: walletRowClasses('product-icon') },
        React.createElement(ProductIconContainer, {
          iconFileName: iconFileName,
          size: iconSize
        })
      ),
      React.createElement(
        'div',
        { className: walletRowClasses('symbol-label') },
        ProductSymbol + ' ' + ProductFullName,
        replaceActivityLink && renderActivityLink()
      )
    ),
    React.createElement(
      'div',
      { className: walletRowClasses('activity-link-columns') },
      !replaceActivityLink && renderActivityLink(),
      !hideActivityBtn &&
        React.createElement(
          'div',
          { className: walletRowClasses('icon-nav-buttons') },
          isCrypto
            ? React.createElement(CryptoNavItemsContainer, {
                product: {
                  iconFileName: iconFileName,
                  ProductSymbol: ProductSymbol,
                  ProductFullName: ProductFullName,
                  ProductId: ProductId
                },
                showIconButtonLabel: false
              })
            : React.createElement(FiatNavItemsContainer, {
                product: {
                  iconFileName: iconFileName,
                  ProductSymbol: ProductSymbol,
                  ProductFullName: ProductFullName,
                  ProductId: ProductId
                },
                showIconButtonLabel: false
              })
        )
    )
  );
};

WalletRow.contextTypes = {
  t: PropTypes.func.isRequired
};

export default WalletRow;
